import React from "react";
import { Link } from "react-router-dom";
import { window } from "browser-monads";
const Header = () => {
  function redirectToLink(event, url) {
    event.preventDefault();
    window.location.href = url;
  }

  return (
    <header style={styles.header}>
      <div style={styles.logoContainer}>
        <img
          src={require("./assets/adoro.png")}
          alt="Adoro Logo"
          style={styles.logo}
        />
      </div>
      <nav style={styles.nav}>
        <Link to="https://adoro.social/" style={styles.navLink}>
          Home
        </Link>
        <Link to="/templates" style={styles.navLink}>
          Templates
        </Link>
        {/* <button
          style={{
            padding: "10px 20px",
            fontSize: 18,
            // fontFamily:"Poppins",
            fontWeight: "500",
            color: "gray",
            background:
              "linear-gradient(45deg, rgba(0,255,255,0.4), rgba(255,192,203,1),rgba(255,255,0,0.5))", // Linear gradient color
            border: "none",
            // alignSelf: "center",
            // marginBottom: 10,
          }}
        >
          Download App
        </button> */}
        <Link to="https://play.google.com/store/apps/details?id=com.adoro.creators" style={styles.navLink}>
          Download
        </Link>
      </nav>
      {/* <li className="header-social" style={{fontSize:20}}>
        <a
          href="#"
          className="d-none d-sm-block"
          onClick={(e) =>
            redirectToLink(
              e,
              "https://twitter.com/adorocreators?t=Hj0FpXJSvmsPsRCdmQWhBw&s=09"
            )
          }
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          href="#"
          onClick={(e) =>
            redirectToLink(
              e,
              "https://www.reddit.com/r/adoro_creators/s/l26l0JC5BI"
            )
          }
        >
          <i className="fab fa-reddit"></i>
        </a>
        <a
          href="#"
          onClick={(e) =>
            redirectToLink(
              e,
              "https://instagram.com/adorocreators?igshid=MzRlODBiNWFlZA=="
            )
          }
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="#"
          //  data-bs-toggle="modal"
          // data-bs-target="#connectModal"
        >
          <i className="fab fa-google-play"></i>
        </a>
      </li> */}
    </header>
  );
};

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "rgba(255,182,193,0.1)",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  logoContainer: {
    marginRight: "auto",
  },
  logo: {
    height: 100,
    width: 100,
  },
  nav: {
    display: "flex",
    gap: "20px",
  },
  navLink: {
    textDecoration: "none",
    color: "gray",
    fontSize: 20,
    fontWeight: "500",
    // fontFamily:"Font Awesome 5 Free",
  },
  downloadButton: {
    padding: "10px 20px",
    fontSize: 18,
    // fontFamily:"Poppins",
    fontWeight: "500",
    color: "gray",
    background:
      "linear-gradient(45deg, rgba(0,255,255,0.4), rgba(255,192,203,1),rgba(255,255,0,0.5))", // Linear gradient color
    border: "none",
  },
};

export default Header;
