// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './Header'; // Adjust the import path as necessary

// const App = () => {
//   const [templates, setTemplates] = useState([]);
//   const [trending, setTrending] = useState([]);
//   const [loading, setLoading] = useState(true); // Flag to indicate loading state

//   const getTemplates = async () => {
//     try {
//       const response = await axios.get(`https://backend-seven-gules.vercel.app/app/user/getalltemplates`);
//       setTemplates(response.data.posts);
//       setLoading(false); // Set loading to false once images are fetched
//     } catch (error) {
//       console.error("Error fetching templates:", error);
//       setLoading(false); // Set loading to false even if there's an error
//     }
//   };

//   const getTrending = async () => {
//     try {
//       const res = await axios.get(`https://backend-seven-gules.vercel.app/app/user/gettrendingtemplate`);
//       setTrending(res.data.trendingtemplate);
//     } catch (err) {
//       console.log('Fetching error:', err);
//     }
//   };

//   useEffect(() => {
//     getTemplates();
//     getTrending();
//   }, []);

//   const renderImageGrid = () => {
//     if (loading) {
//       return (
//         <div className="loading-container">
//           <p>Loading images...</p>
//         </div>
//       );
//     }

//     return (
//       <div style={{backgroundColor: "rgba(255,182,193,0.2)"}}>
//         <Header/>
//         <div style={{textAlign:"center",justifyContent:"center"}}>
//         <h1 style={{color:"gray",fontWeight:"400", fontFamily:"Unicons"}}>
//        Unleash your creativity with unrivaled images
//         </h1>
//         </div>
//         <div className="main-container" style={{ display: "flex", flexDirection: "row" }}>
//           <div className="templates-section" style={{ flex: 2 }}>
//             <div className="image-grid" style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
//               {templates.map((template) => (
//                 <div key={template.id} className="image-item" style={{ margin: 10 }}>
//                   <img
//                     src={`https://www.adoro.social/Template/Image/${template.fileName}`}
//                     alt={template.altText || "Image description"}
//                     style={{ height: 200, width: 200, marginTop: 20 }}
//                   />
//                   <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 , gap:10}}>
//                     <h5 style={{fontFamily:"Unicons",color:"gray", margin: 0 ,fontWeight:"400", width:"60%"}}>{template.name}</h5>
//                     <img
//                       src={require("./assets/Union.png")}
//                       style={{ backgroundColor: "white", marginRight: 5 }}
//                       alt="Union Icon"
//                     />
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <Router>
//       {/* <Header /> */}
//       <Routes>
//         <Route path="/" element={renderImageGrid()} />
//         <Route path="/templates" element={renderImageGrid()} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './Header'; // Adjust the import path as necessary
// import './App.css'; // Ensure to import your CSS file

// const App = () => {
//   const [templates, setTemplates] = useState([]);
//   const [trending, setTrending] = useState([]);
//   const [loading, setLoading] = useState(true); // Flag to indicate loading state

//   const getTemplates = async () => {
//     try {
//       const response = await axios.get(`https://backend-seven-gules.vercel.app/app/user/getalltemplates`);
//       setTemplates(response.data.posts);
//       setLoading(false); // Set loading to false once images are fetched
//     } catch (error) {
//       console.error("Error fetching templates:", error);
//       setLoading(false); // Set loading to false even if there's an error
//     }
//   };

//   const getTrending = async () => {
//     try {
//       const res = await axios.get(`https://backend-seven-gules.vercel.app/app/user/gettrendingtemplate`);
//       setTrending(res.data.trendingtemplate);
//     } catch (err) {
//       console.log('Fetching error:', err);
//     }
//   };

//   useEffect(() => {
//     getTemplates();
//     getTrending();
//   }, []);

//   const renderImageGrid = () => {
//     if (loading) {
//       return (
//         <div className="loading-container">
//           <p>Loading images...</p>
//         </div>
//       );
//     }

//     return (
//       <div style={{ backgroundColor: "rgba(255,182,193,0.2)" }}>
//         <Header />
//         <div style={{ textAlign: "center", justifyContent: "center" }}>
//           <h1 style={{ color: "gray", fontWeight: "400", fontFamily: "Unicons" }}>
//             Unleash your creativity with unrivaled images
//           </h1>
//         </div>
//         <div className="main-container" style={{ display: "flex", flexDirection: "row" }}>
//           <div className="templates-section" style={{ flex: 2 }}>
//             <div className="image-grid" style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
//               {templates.map((template) => (
//                 <div key={template.id} className="image-item">
//                   <img
//                     src={`https://www.adoro.social/Template/Image/${template.fileName}`}
//                     alt={template.altText || "Image description"}
//                     // style={{height:200,width:200}}
//                   />
//                   <div className="image-overlay">
//                     <h5 style={{ fontFamily: "Unicons", color: "white", margin: 0, fontWeight: "400", width: "60%" }}>{template.name}</h5>
//                     <img
//                       src={require("./assets/Union.png")}
//                       style={{ backgroundColor: "white", marginRight: 5,height:20,width:20 }}
//                       alt="Union Icon"
//                     />
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <Router>
//       {/* <Header /> */}
//       <Routes>
//         <Route path="/" element={renderImageGrid()} />
//         <Route path="/templates" element={renderImageGrid()} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Header from './Header'; // Adjust the import path as necessary
// import './App.css'; // Ensure to import your CSS file

// const App = () => {
//   const [templates, setTemplates] = useState([]);
//   const [trending, setTrending] = useState([]);
//   const [loading, setLoading] = useState(true); // Flag to indicate loading state

//   const getTemplates = async () => {
//     try {
//       const response = await axios.get(`https://backend-seven-gules.vercel.app/app/user/getalltemplates`);
//       setTemplates(response.data.posts);
//       setLoading(false); // Set loading to false once images are fetched
//     } catch (error) {
//       console.error("Error fetching templates:", error);
//       setLoading(false); // Set loading to false even if there's an error
//     }
//   };

//   const getTrending = async () => {
//     try {
//       const res = await axios.get(`https://backend-seven-gules.vercel.app/app/user/gettrendingtemplate`);
//       setTrending(res.data.trendingtemplate);
//     } catch (err) {
//       console.log('Fetching error:', err);
//     }
//   };

//   useEffect(() => {
//     getTemplates();
//     getTrending();
//   }, []);

//   const renderImageGrid = () => {
//     if (loading) {
//       return (
//         <div className="loading-container">
//           <p>Loading images...</p>
//         </div>
//       );
//     }

//     return (
//       <div style={{ backgroundColor: "rgba(255,182,193,0.2)" }}>
//         <Header />
//         <div className="header" style={{ position: 'relative', textAlign: 'center' }}>
//   <img
//     src={require("./assets/download.png")}
//     alt="Adoro Logo"
//     style={{ height: 400, width: '90%' }}
//   />
//   <h1 style={{ position: 'absolute', top: '10%', width: '100%', color: '#fff', textShadow: '1px 1px 2px #000' }}>
//     Unleash your creativity with unrivaled images
//   </h1>
//   <input
//     type="text"
//     style={{
//       position: 'absolute',
//       top: '50%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       zIndex: 2,
//       padding: '10px',
//       width:"60%",
//       fontSize: '16px',
//       alignSelf:"center"
//     }}
//     placeholder="Search for images"
//   />
// </div>

//         <div className="main-container">
//           <div className="templates-section">
//             <div className="image-grid">
//               {templates.map((template) => (
//                 <div key={template.id} className="image-item" style={{marginRight:120,alignSelf:"center"}}>
//                   <img
//                     src={`https://www.adoro.social/Template/Image/${template.fileName}`}
//                     alt={template.altText || "Image description"}
//                   />
//                   <div className="image-overlay">
//                     <h5 style={{ fontFamily: "Unicons", margin: 0, fontWeight: "400", width: "60%" }}>{template.name}</h5>
//                     <a href="https://play.google.com/store/apps/details?id=com.adoro.creators" target="_blank" rel="noopener noreferrer">
//                     <img
//                       src={require("./assets/Union.png")}
//                       style={{height:20,width:20, backgroundColor: "white", marginRight: 5 }}
//                       alt="Union Icon"
//                     />
//                   </a>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={renderImageGrid()} />
//         <Route path="/templates" element={renderImageGrid()} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header"; // Adjust the import path as necessary
import "./App.css"; // Ensure to import your CSS file

const App = () => {
  const [templates, setTemplates] = useState([]);
  const [trending, setTrending] = useState([]);
  const [loading, setLoading] = useState(true); // Flag to indicate loading state
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const getTemplates = async () => {
    try {
      const response = await axios.get(
        `https://backend-seven-gules.vercel.app/app/user/getalltemplates`
      );
      setTemplates(response.data.posts);
      setLoading(false); // Set loading to false once images are fetched
    } catch (error) {
      console.error("Error fetching templates:", error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };

  const getTrending = async () => {
    try {
      const res = await axios.get(
        `https://backend-seven-gules.vercel.app/app/user/gettrendingtemplate`
      );
      setTrending(res.data.trendingtemplate);
    } catch (err) {
      console.log("Fetching error:", err);
    }
  };

  useEffect(() => {
    getTemplates();
    getTrending();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderImageGrid = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <p>Loading images...</p>
        </div>
      );
    }

    const filteredTemplates = templates.filter((template) =>
      template.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div style={{ backgroundColor: "rgba(255,182,193,0.2)" }}>
        <Header />
        <div
          className="header"
          style={{ position: "relative", textAlign: "center" }}
        >
          <img
            src={require("./assets/download.jpeg")}
            alt="Adoro Logo"
            style={{ width: "90%" }}
          />
          <h1
            style={{
              fontFamily:"fantasy",
              // fontSize: 20,
              // fontWeight: "300",
              position: "absolute",
              top: "20%",
              width: "100%",
              color: "#000",
              // textShadow: "1px 1px 2px #000",
            }}
          >
            Unleash your creativity with unrivaled images
          </h1>
          <input
            type="text"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              padding: "10px",
              width: "60%",
              fontSize: "16px",
              alignSelf: "center",
            }}
            placeholder="Search for images"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="main-container">
          <div className="templates-section">
            <div className="image-grid">
              {filteredTemplates.map((template) => (
                <div
                  key={template.id}
                  className="image-item"
                  style={{ marginRight: 120, alignSelf: "center" }}
                >
                  <img
                    src={`https://www.adoro.social/Template/Image/${template.fileName}`}
                    alt={template.altText || "Image description"}
                  />
                  <div className="image-overlay">
                    <h5
                      style={{
                        fontFamily: "Unicons",
                        margin: 0,
                        fontWeight: "400",
                        width: "60%",
                      }}
                    >
                      {template.name}
                    </h5>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.adoro.creators"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("./assets/Union.png")}
                        style={{
                          height: 20,
                          width: 20,
                          backgroundColor: "white",
                          marginRight: 5,
                        }}
                        alt="Union Icon"
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={renderImageGrid()} />
        <Route path="/templates" element={renderImageGrid()} />
      </Routes>
    </Router>
  );
};

export default App;
